import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {LinkedItems} from '../../util'
import {KenticoBodyItemData} from '../body-item'
import {KenticoPageLinkData} from '../page-link'
import {KenticoPageScriptData} from '../page-script'
import {KenticoRichTextData} from '../rich-text'
import {KenticoSchemaData} from '../schema'
import {KenticoFormSubmissionRedirectData} from '../form-submission-redirect'

/** Form Page. */
export const KenticoFormPageData = IO.intersection(
  [
    KenticoPageLinkData,
    IO.type({
      elements: IO.type({
        body: LinkedItems(KenticoBodyItemData),
        canonicalPage: LinkedItems(KenticoPageLinkData),
        description: IO.type({
          value: IO.string,
        }),
        formType: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.keyof({
                prequal_cash_advance: undefined,
                prequal_installment: undefined,
                prequal_title: undefined,
              }),
            }),
          ),
        }),
        mainText: KenticoRichTextData,
        postBody: LinkedItems(KenticoBodyItemData),
        preBody: LinkedItems(KenticoBodyItemData),
        privacyConsent: KenticoRichTextData,
        robots: IO.type({
          value: IO.array(
            IO.type({
              codename: IO.string,
            }),
          ),
        }),
        schema: LinkedItems(KenticoSchemaData),
        scripts: LinkedItems(KenticoPageScriptData),
        submissionLinks: LinkedItems(KenticoFormSubmissionRedirectData),
        tcpa: KenticoRichTextData,
        title: IO.type({
          value: IO.string,
        }),
      }),
      system: IO.type({
        id: IO.string,
        name: IO.string,
        type: IO.literal('form_page'),
      }),
    }),
  ],
  'KenticoFormPage',
)

/** Data type. */
export interface KenticoFormPageData
  extends IO.TypeOf<typeof KenticoFormPageData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoFormPage on Node {
    ... on kontent_item_form_page {
      ...KenticoPageLink
      system {
        codename
        id
        name
        type
      }
      elements {
        submissionLinks: submission_links {
          nodes: value {
            ...KenticoFormSubmissionRedirect
          }
        }
        preBody: pre_post_body__pre_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        postBody: pre_post_body__post_body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        formType: form_type {
          value {
            codename
          }
        }
        body {
          nodes: value {
            ...KenticoBodyItem
          }
        }
        canonicalPage: metadata__canonical_page {
          nodes: value {
            ...KenticoPageLink
          }
        }
        description: metadata__description {
          value
        }
        robots: metadata__robots {
          value {
            codename
          }
        }
        schema: metadata__schema {
          nodes: value {
            ...KenticoSchema
          }
        }
        scripts: metadata__scripts {
          nodes: value {
            ...KenticoPageScript
          }
        }
        title: metadata__title {
          value
        }
        mainText: main_text {
          ...KenticoRichText
        }
        tcpa {
          ...KenticoRichText
        }
        privacyConsent: privacy_consent {
          ...KenticoRichText
        }
      }
    }
  }
`
