import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React from 'react'
import {
  KenticoFormPage,
  KenticoFormPageData,
} from '~/kentico/components/form-page'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    page: KenticoFormPageData,
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!) {
    page: kontentItemFormPage(id: {eq: $id}) {
      ...KenticoFormPage
    }
    ...KenticoGlobalScripts
  }
`

const FormPage = (props: unknown) => {
  const {data} = useDecode(Props, props)
  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <KenticoFormPage {...data} />
    </GatsbyLayout>
  )
}

/** Form page. */
// eslint-disable-next-line import/no-default-export
export default FormPage
